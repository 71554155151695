import {useState, useEffect} from 'react';
import fetch, {WPFetch} from 'aac-components/utils/fetch';

function useFetch(url, options = {}) {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function getData() {
            let response = {};
            try {
                response = await fetch(url, options);
                const responseData = await response.json();
                setData(responseData);
            } catch (e) {
                console.warn(e);
            }
        }
        getData();
    }, []);

    return {data};
}

export default useFetch;

export const useWpFetch = (url, clearCache = false) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        async function getData() {
            let response = {};
            try {
                response = await WPFetch(url, clearCache);
                const responseData = await response?.data;
                setData(responseData);
            } catch (e) {
                console.warn(e);
            }
        }
        getData();
    }, []);

    return {data};
};
