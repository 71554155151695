import React from 'react';

export const initialStickyFooterState = {
    isStickyFooterExpanded: false,
    isStickyFooterInView: false,
};

const StickyFooterContext = React.createContext(initialStickyFooterState);

export default StickyFooterContext;
