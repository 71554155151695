import {isSpanishPage, parseYoastHead} from '../../lib/utils';
import Head from 'next/head';
import {useRouter, withRouter} from 'next/router';
import {useEffect, useState} from 'react';
import url from 'url';

function removeTrailingSlash(url) {
    const lastChar = url.substr(-1);
    if (lastChar === '/') {
        url = url.substring(0, url.length - 1);
    }
    return url;
}

const hrefLangBlacklist = ['/esp', '/verify-tx-landing-page-spanish'];

const SeoHead = ({data = []}) => {
    data = typeof data?.[0] === 'object' && data?.[0] !== null ? data?.[0] : data;
    const {yoast_head = '', slug = '', link = '', spanish_version = false} = data;

    const {asPath} = useRouter();
    const {pathname} = url.parse(asPath);

    const [shareableLink, setShareableLink] = useState('');

    useEffect(() => {
        let sharable = '';
        if (link && typeof link === 'string') {
            sharable = link.replace(
                /admin.americanaddictioncenters.org|stagingadmin.americanaddictioncenters.org|local.admin.americanaddictioncenters.org/g,
                'americanaddictioncenters.org',
            );
        } else {
            // if there is no link from wordpress, build one
            sharable = location.protocol + '//' + location.host + location.pathname;
        }
        sharable = removeTrailingSlash(sharable);
        setShareableLink(sharable);
    }, [link]);

    const isSpanish = isSpanishPage(slug);
    const alternateLink = isSpanish
        ? shareableLink.replace(/\/esp/g, '')
        : `${shareableLink}/esp`;

    const defaultLink = isSpanish ? shareableLink.replace(/\/esp/g, '') : shareableLink;

    const includeHrefLang =
        (isSpanish || spanish_version) && !hrefLangBlacklist.includes(pathname); // if it is a Spanish page itself, or if there is a Spanish version of the page

    return (
        <Head>
            {(yoast_head && parseYoastHead(yoast_head)) || null}

            {includeHrefLang && (
                <>
                    <link
                        rel="alternate"
                        key="alternate-1"
                        href={shareableLink}
                        hrefLang={isSpanish ? 'es-us' : 'en-us'}
                    />
                    <link
                        rel="alternate"
                        key="alternate-2"
                        href={alternateLink}
                        hrefLang={isSpanish ? 'en-us' : 'es-us'}
                    />
                    <link
                        rel="alternate"
                        key="alternate-default"
                        href={defaultLink}
                        hrefLang="x-default"
                    />
                </>
            )}
        </Head>
    );
};
export default withRouter(SeoHead);
