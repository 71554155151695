import {useRouter} from 'next/router';
import {useContext, useEffect, useState} from 'react';
import DriftContext from './DriftContext';
import url from 'url';
import StickyFooterContext from '../StickyFooter/StickyFooterContext';
import {BREAKPOINT} from 'aac-components/utils/styles';
import AppContext from '../AppContext';

const Drift = () => {
    const {driftLoaded, setDriftLoaded, isChatActive, setIsChatActive} =
        useContext(DriftContext);

    const {isMobile} = useContext(AppContext);

    const {asPath} = useRouter();
    const {pathname} = url.parse(asPath);

    const {
        stickyFooterState: {isStickyFooterExpanded, isStickyFooterInView},
    } = useContext(StickyFooterContext);

    const chatBottom =
        isChatActive || !isStickyFooterInView
            ? '15px'
            : isStickyFooterExpanded
            ? isMobile
                ? '210px'
                : '210px'
            : '70px';

    const hideElements = (hide) => {
        const ab_stickyFooter =
            document && document.getElementById('abtest_sticky-footer');
        const stickyFooter = document && document.getElementById('sticky-footer-cta');
        const geoTargetTab = document && document.getElementById('geotarget-tab');
        const chatBubble = document && document.getElementById('chat-bubble');

        if (hide) {
            ab_stickyFooter && ab_stickyFooter.setAttribute('style', 'display: none;');
            stickyFooter && stickyFooter.setAttribute('style', 'display: none;');
            geoTargetTab && geoTargetTab.setAttribute('style', 'display: none;');
            chatBubble && chatBubble.setAttribute('style', 'display: none;');
        } else {
            ab_stickyFooter && ab_stickyFooter.setAttribute('style', 'display: block;');
            stickyFooter && stickyFooter.setAttribute('style', 'display: block;');
            geoTargetTab && geoTargetTab.setAttribute('style', 'display: flex;');
            chatBubble && chatBubble.setAttribute('style', 'display: block;');
        }
    };

    // handle drift api after it's loaded
    useEffect(() => {
        if (driftLoaded) {
            window.drift &&
                drift.on &&
                drift.on('ready', function (api, payload) {
                    // window?.drift?.api?.hideChat();
                    // window?.drift?.api?.widget.hide();
                    // listeners
                    drift.on('chatOpen', function () {
                        hideElements(true);
                        setIsChatActive(true);
                    });
                    drift.on('chatClose', function () {
                        //api.widget.hide();
                        hideElements(false);
                        setIsChatActive(false);
                    });
                    // set convo id
                    // drift.on('startConversation', function (data) {
                    //     sessionStorage.setItem('drift_chat', JSON.stringify(data));
                    // });
                });
        }
    }, [driftLoaded]);

    useEffect(() => {
        if (isChatActive) {
            window?.drift?.api?.openChat();
            const delay = setTimeout(() => {
                hideElements(true);
            }, 1000);
            return () => clearTimeout(delay);
        }
        // else {
        //     window?.drift?.api?.hideChat();
        //     window?.drift?.api?.widget.hide();
        // }
    }, [asPath]);

    // mutation observer for when drift is loaded
    useEffect(() => {
        const observer = new MutationObserver(() => {
            const driftScript = document.getElementById('drift-script');
            if (driftScript) {
                setDriftLoaded(true);
            } else {
                setDriftLoaded(false);
            }
        });
        observer.observe(document, {
            childList: true,
            subtree: true,
        });
        return () => observer.disconnect();
    }, []);

    return (
        <>
            <style jsx>
                {`
                    :global(#drift-frame-controller) {
                        z-index: 20 !important;
                        bottom: ${chatBottom} !important;
                    }
                    :global(#drift-frame-chat) {
                        bottom: 0px !important;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        :global(#drift-frame-chat) {
                            bottom: ${isChatActive ? '65px' : '0px'} !important;
                        }
                    }
                `}
            </style>
        </>
    );
};
export default Drift;
