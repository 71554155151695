import React from 'react';

export const initialAppState = {
    activeExperiments: {},
    history: [],
    acfOptions: {
        acf: {},
    },
    menuItems: [],
    isMobile: true,
    dynamicKeywords: {},
    callTrackingNumber: {
        href: '',
        display: '',
        base: '',
    },
    sessionStorageState: {
        geolocation: {},
    },
    segment: {
        name: '',
        number: null,
    },
    liftAiScore: 0,
};

const AppContext = React.createContext(initialAppState);

export default AppContext;
