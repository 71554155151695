import {useRouter} from 'next/router';
import Link from 'next/link';

const TopReasonsLink = ({slug = '', name = ''}) => {
    const {asPath} = useRouter();

    if (
        asPath.includes('/rehab-guide/reasons-choose') ||
        slug.includes('outpatient') ||
        slug.includes('resolutions')
    )
        return null;

    return (
        <div className="resources">
            <div className="resources__title">Useful Resources:</div>
            <div className="resources__link">
                <Link
                    href={`/rehab-guide/reasons-choose-${slug}`}
                    title={`Top Reasons to Choose ${name}`}
                    style={{marginTop: '0'}}>
                    Top Reasons to Choose {name}
                </Link>
            </div>
            <style jsx>
                {`
                    .resources {
                        margin: 16px 0;
                        border-top: 2px solid var(--gray-200);
                        padding-top: 16px;
                    }
                    .resources__title {
                        font-weight: bold;
                        font-size: 14px;
                        margin-bottom: 16px;
                    }
                    .resources__link {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                    }
                    .resources__link:before {
                        content: '';
                        display: block;
                        width: 6px;
                        height: 6px;
                        margin-right: 16px;
                        background: var(--gray-300);
                        border-radius: 100%;
                    }
                `}
            </style>
        </div>
    );
};
export default TopReasonsLink;
